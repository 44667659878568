import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'
import { useLocalStorage } from 'react-use'

import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ILevels } from 'common/interfaces/ILevels'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'
import { useLazyFetchLevelsQuery } from 'common/store/api/rootApi'

interface IProps {
  levels: ILevels
  isFetching: boolean
  updatePoints: (points: number) => void
  fetchLevels: (points: number) => Promise<void>
}

const SitePointsContext = createContext<IProps | null>(null)

export const SitePointsProvider = ({ children }: { children: ReactNode }) => {
  const { processApiResponse } = useApiResponse()

  const [fetch, { isFetching }] = useLazyFetchLevelsQuery()
  const [levels, setLevels] = useLocalStorage<ILevels | null>(LOCALE_STORAGE_KEYS.LEVELS, null)
  const updatePoints = (points: number): void => {
    if (levels) setLevels({ ...levels, points })
  }

  const fetchLevels = async (points: number): Promise<void> => {
    setLevels({ items: [], point_per_dollar: {}, points })
    const response = await fetch()
    processApiResponse(response as ApiResponse, {
      successCallback: (): void => {
        setLevels({ ...response.data, points })
      },
    })
  }

  const value = useMemo(
    () => ({ levels, updatePoints, fetchLevels, isFetching }),
    [levels, isFetching],
  )

  return <SitePointsContext.Provider value={value}>{children}</SitePointsContext.Provider>
}

export const useSitePoints = () => {
  return useContext(SitePointsContext) as IProps
}
