import type { ReactNode } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import LinkedInTag from 'react-linkedin-insight'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage, useToggle } from 'react-use'

import { notification as antNotification } from 'antd'
import { PATHS } from 'routes'

import { useSitePoints } from 'app/providers/sitePoints/SitePointsProvider'

import { INSIGHT_CONSTANTS } from 'common/constants/insightConstants'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppDispatch } from 'common/hooks/redux'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'
import type { IUser } from 'common/interfaces/IUser'
import { USER_ROLE } from 'common/interfaces/IUser'
import { APP_API_LIST } from 'common/services/stateService'

import { useLogoutAsDoctorMutation } from 'features/Admin/state/api/adminApi'
import { useLoginMutation, useLogoutMutation, useRegisterMutation } from 'features/Auth'
import type { IAuthLogin, IAuthRegister } from 'features/Auth/interfaces/IAuth'
import type { IUploadedValidationDocument } from 'features/Auth/interfaces/IAuthUploadedDocument'

interface IProps {
  isDoctor: boolean
  isStaff: boolean
  hasCompletedDocuments: boolean
  hasIncompleteDocuments: boolean
  user: IUser | null
  error: string | null
  isLoading: boolean
  isSuperAdminAsDoctor: boolean
  logout: () => void
  resetError: () => void
  login: (data: IAuthLogin) => Promise<void>
  loginAsDoctor: (data: any) => void
  register: (data: IAuthRegister) => Promise<boolean>
  updateName: (first_name: string, last_name: string) => void
  updatePhoneNumber: (phone_number: string) => void
  updateDocuments: (documents: IUploadedValidationDocument[]) => void
  logoutAsDoctor: () => void
  setUser: (user: IUser | null) => void
  isSidebarVisible?: boolean
  toggleIsSidebarVisible?: () => void
}

const AuthContext = createContext<IProps>(null)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [doLogout]: ITriggerRequest = useLogoutMutation()
  const [logOutAsDoctor] = useLogoutAsDoctorMutation()
  const { fetchLevels } = useSitePoints()
  const [doLogin, { isLoading: isLoadingLogin }]: ITriggerRequest = useLoginMutation()
  const [doRegister, { isLoading: isLoadingRegister }]: ITriggerRequest = useRegisterMutation()

  const [error, setError] = useState<string | null>(null)
  const [user, setUser] = useLocalStorage<IUser | null>(LOCALE_STORAGE_KEYS.USER, null)
  const [isSidebarVisible, toggleIsSidebarVisible] = useToggle(false)
  const logout = (): void => {
    doLogout()
    setUser(null)
    window.localStorage.clear()
    navigate(PATHS.LOGIN, { replace: true })
    APP_API_LIST.forEach((reducer): void => {
      dispatch(reducer.util.resetApiState())
    })

    antNotification.destroy()
  }

  const login = async (data: IAuthLogin): Promise<void> => {
    LinkedInTag.track(INSIGHT_CONSTANTS.LOGIN)
    TagManager.dataLayer({ dataLayer: { event: INSIGHT_CONSTANTS.LOGIN } })
    const response = await doLogin(data)
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.ERROR)) setError(response.error.data[0])
    else {
      const { data } = response
      setUser({
        ...data.profile,
        token: data.access_token,
        documents: data.documents,
        is_super_doctor: data.is_super_doctor,
      })
      const isValidUser =
        data.documents?.length >= 2 &&
        data.documents.every((documentUploaded: IUploadedValidationDocument): boolean =>
          Boolean(documentUploaded.is_valid),
        )

      if (isValidUser || data?.profile?.role?.name === USER_ROLE.STAFF) {
        navigate(PATHS.HOME_ONGOING)
      } else {
        navigate(PATHS.VALIDATE_IDENTITY)
      }

      fetchLevels(data.profile?.points)
    }
  }

  const loginAsDoctor = async (data: any): Promise<void> => {
    const { profile, documents, access_token, old_access_token } = data
    setUser({
      ...profile,
      token: access_token,
      documents,
      old_access_token,
    })

    navigate(PATHS.HOME_ONGOING)
    window.location.reload()
  }

  const logoutAsDoctor = async (): Promise<void> => {
    try {
      const response = await logOutAsDoctor(user?.old_access_token)
      const { data } = response
      const { profile, documents, access_token, is_super_doctor } = data
      setUser({
        ...profile,
        token: access_token,
        documents,
        is_super_doctor,
      })
      navigate(PATHS.ADMIN)
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  const resetError = (): void => {
    setError(null)
  }

  const register = async (registerData: IAuthRegister): Promise<boolean> => {
    LinkedInTag.track(INSIGHT_CONSTANTS.REGISTER)
    TagManager.dataLayer({ dataLayer: { event: INSIGHT_CONSTANTS.REGISTER } })
    const response = await doRegister(registerData)
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.ERROR)) {
      setError(response.error.data[0])
      return false
    }
    return true
  }

  const updateDocuments = (documents: IUploadedValidationDocument[]): void => {
    setUser({ ...user, documents })
  }

  const updateName = (first_name: string, last_name: string): void => {
    setUser({ ...user, first_name, last_name })
  }

  const updatePhoneNumber = (phone_number: string): void => {
    setUser({ ...user, phone_number })
  }

  const value = useMemo(() => {
    const hasCompletedDocuments =
      user?.documents?.length >= 2 &&
      user?.documents.every(({ is_valid }: IUploadedValidationDocument) => Boolean(is_valid))

    const hasIncompleteDocuments =
      user?.documents?.length >= 2 &&
      user?.documents.some(({ is_valid }: IUploadedValidationDocument) => is_valid === null)

    return {
      user,
      error,
      login,
      loginAsDoctor,
      logout,
      register,
      resetError,
      updateName,
      updatePhoneNumber,
      updateDocuments,
      logoutAsDoctor,
      setUser,
      isLoading: isLoadingRegister || isLoadingLogin,
      isDoctor: user && user.role.name === USER_ROLE.DOCTOR,
      isStaff: user && user.role.name === USER_ROLE.STAFF,
      isSuperAdminAsDoctor: Boolean(user?.old_access_token),
      hasCompletedDocuments,
      hasIncompleteDocuments,
      isSidebarVisible,
      toggleIsSidebarVisible,
    }
  }, [user, error, isLoadingRegister, isLoadingLogin, isSidebarVisible])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext) as IProps
}
