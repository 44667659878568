import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { baseQuery } from 'common/store/api/fetchBaseQuery'

import type { ILease, BookingOption, BookingOptionTypes } from 'features/Home/interfaces/IHome'

export const homeApi = createApi({
  reducerPath: REDUCER_CONSTANTS.HOME,
  baseQuery: baseQuery(),
  tagTypes: ['Leases'],
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchRooms: build.query<
      {
        rooms: ISiteRoom[]
        site_options: ISiteRoom[]
        medical_staff: ISiteRoom[]
      },
      string
    >({
      query: (connection) => ({
        url: '/newlease',
        headers: {
          'Database-Connection': connection,
        },
      }),
    }),
    fetchLeases: build.query<ILease[], 'ongoing' | 'completed'>({
      query: (type) => ({
        url: `/leases?filter=${type}`,
      }),
      providesTags: ['Leases'],
      transformResponse: (data: { items: ILease[] }): ILease[] => data.items,
    }),

    fetchBookingOption: build.query<BookingOption, { request_url_id: number; connection: string }>({
      query: ({ request_url_id, connection }) => ({
        url: `/booking/${request_url_id}/option`,
        headers: {
          'Database-Connection': connection,
        },
      }),
      providesTags: ['Leases'],
    }),

    fetchBookingTypes: build.query<BookingOptionTypes, string>({
      query: (connection) => ({
        url: '/booking/types',
        headers: {
          'Database-Connection': connection,
        },
      }),
      providesTags: ['Leases'],
    }),
  }),
})

export const {
  useLazyFetchRoomsQuery,
  useFetchLeasesQuery,
  useLazyFetchBookingTypesQuery,
  useFetchBookingTypesQuery,
  useFetchBookingOptionQuery,
  useLazyFetchBookingOptionQuery,
} = homeApi
