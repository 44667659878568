export enum INFO_CONSTANTS {
  BOOKING_NUMBER = 'Booking Number',
  PATIENT_ACTIVITY_TITLE = 'Real-time Patient Tracker',
  STAFF = 'Staff',
  CANT_EDIT = 'You can’t edit/change this booking',
  LEASE_VALUE = 'Booking value',
  MEDIA = 'Media',
  PATIENT_ACTIVITY = 'Patient Schedule',
  DOCUMENTS = 'Documents',
  CONTRACTS = 'Contracts',
  LEASE_MEDICAL_STAFF = 'Book’s Medical Staff',
  BILLING = 'Billing',
  REAL_TIME_ACTIVITY = 'Real time activity',
  ACTIVITY_HISTORY = 'Movement history today',
  PRE_POPULATE_USING = 'Pre-populate schedules using',
  TIME_SLOTS = 'time slots',
  MEDIA_DESCRIPTION = 'One or more rooms that you booked, give you the possibility to display custom images (diplomas, certificates, etc) for your patients, on dedicated displays',
  STILL_ACTIVE = 'The booking is still active',
  SELECT_DRAG = 'Select a file or drag and drop here',
  FILE_TYPES = 'JPG or PNG, file size no more than 10MB',
  DIMENSIONS = 'Image dimensions: max 1920x1080px',
  SHOP = 'Shop',
  OPTIONS = 'Options',
  AFTER_LEASE = 'Room condition after the booking ends',
  BEFORE_LEASE = 'Room condition before booking start',
  SHOP_DESCRIPTION = 'If the booking is active, you can buy extra products from here just for current day',
  DISPLAY = 'Display ',
  CURRENT_DAY_MESSAGE = 'You can only make changes for the next days of your booking. If you want to make changes for today please go to ',
  PAST_DAY_MESSAGE = 'You can not make changes for the past days of your booking',
  ADD_PATIENT = 'Add patient',
  EDIT_PATIENT = 'Edit patient',
  ADD_WALK_IN_PATIENT = 'Add walk-in patient',
  EDIT_WALK_IN_PATIENT = 'Reschedule patient',
  NO_ACTIVE_LEASES_TODAY = 'No active bookings today',
  SAME_TIME_ALLOWED_PATIENTS = 'Maximum number of people allowed in simultaneously',
  REMOVE_PATIENT = 'This will delete the appointment record',
  PATIENT_CODE = "Patient's code",
  NOTIFY_PATIENT = 'Notify patient',
  STATUS = 'Status:',
  LOCATION = 'Location:',
  NOW_IN = 'Now in:',
  MULTIPLE_PATIENTS_TITLE = 'You already have one patient in this room',
  CURRENT_TIME_EXCEEDED = 'Your selected start time has passed. Change the appointment start time to the current time?',
  ATTENTION = 'Attention',
  ARE_YOU_SURE = 'Are you sure?',
  YES = 'Yes',
  INFO = 'Info',
  CONTRACT = 'contract',
  BUSY_ROOM_WARNING = 'You can’t add or update the start time of patients appointment while room status is set to busy',
  DISABLED_INTERVAL_WARNING = `You can't schedule an appointment during unavailable slots.`,
  NOTIFY_PATIENTS_WARNING_TITLE = 'Notify patients',
  NOTIFY_PATIENTS_WARNING = 'Are you sure you want to notify your patients via Text Message?',
  SUCCESS_SMS = 'Message sent successfully',
  FAILED_SMS = 'There was an error sending the message',
  PENDING_SEND_SMS = 'Message is waiting to be send',
  REASON = 'Reason: ',
  LAST_SMS_SENT = 'Last Text Message sent on',
  ERROR = 'Error: ',
  NO_ACTIVE_ROOMS_TODAY = 'The booking it’s not ready yet, you will be able to manage it after 06:00 AM',
  GAP_LIMIT_ERROR = 'The start and end time gap should be a minimum of 5 minutes.',
  TIME_BETWEEN = 'Please enter a time between ',
  AND = 'and',
  INVALID_TIME = 'Invalid time',
  NO_MUSIC_OPTION = 'No ambient music',
  DEFAULT_PATIENTS_LANGUAGE = 'English (United States)',
  PLEASE_SELECT_START_TIME = 'Please select the start time',
  PLEASE_SELECT_END_TIME = 'Please select the end time',
  EARLY_END_TIME_ERROR_MESSAGE = 'End time cannot be earlier than or equal to start time',
  EARLY_END_TIME_FIVE_MINUTES_GAP_ERROR = 'End time should be at least 5 minutes later than start time',
  END_OF_THE_PROGRAM = 'End time should be at least 5 minutes later than start time and before closing the program',
  ARE_YOU_SURE_TERMINATE_LEASE = 'Are you sure you want to complete booking',
  NOTE = 'Note',
  SITE_MANAGER = 'Site Manager',
  CANCEL_NOTE_1 = 'You must contact the',
  CANCEL_NOTE_2 = ' and schedule a date and time to remove your equipment. Until this is completed, your booking will continue to be in effect.',
}

export const PATIENT_SCHEDULE_SCROLL_HEIGHT_OFFSET = 239
export const CHECK_FOR_DISABLED_CHANGE_ROOM_STATUS_INTERVAL = 60000

export enum PATIENT_STATUSES {
  DISABLED = 0,
  UNCONFIRMED = 1,
  CONFIRMED = 2,
  REFUSED = 3,
  CANCELED = 4,
  CHECKED_IN_ON_SITE = 5,
  CHECKED_OUT = 6,
  WALK_IN = 7,
  NO_SHOW = 8,
  CHECK_IN_REJECTED = 9,
  DELETED = 10,
  RESCHEDULE = 11,
}

export const PATIENT_STATUS_NAMES = {
  [PATIENT_STATUSES.DISABLED]: 'Disabled',
  [PATIENT_STATUSES.UNCONFIRMED]: 'Unconfirmed',
  [PATIENT_STATUSES.CONFIRMED]: 'Confirmed',
  [PATIENT_STATUSES.REFUSED]: 'Refused',
  [PATIENT_STATUSES.CHECKED_IN_ON_SITE]: 'Checked in on site',
  [PATIENT_STATUSES.CANCELED]: 'Canceled',
  [PATIENT_STATUSES.CHECKED_OUT]: 'Checked out',
  [PATIENT_STATUSES.WALK_IN]: 'Walk-in',
  [PATIENT_STATUSES.NO_SHOW]: 'No show',
  [PATIENT_STATUSES.CHECK_IN_REJECTED]: 'No access',
  [PATIENT_STATUSES.DELETED]: 'Deleted',
  [PATIENT_STATUSES.RESCHEDULE]: 'Reschedule (Canceled)',
}

export enum ROOM_STATUS {
  BUSY = 1,
  FREE = 2,
  CLEANUP = 3,
  DISABLE = 4,
}

export const ROOM_STATUS_NAMES = {
  [ROOM_STATUS.BUSY]: 'Busy',
  [ROOM_STATUS.FREE]: 'Free',
  [ROOM_STATUS.CLEANUP]: 'Cleanup',
  [ROOM_STATUS.DISABLE]: 'Disable',
}

export enum ACTION_TYPES {
  RESCHEDULE,
  RESIZE_SCHEDULE,
  DELETE,
  CANCEL,
  CHECK_OUT,
  EDIT_TEMPERATURE,
  EDIT_CONSULTATION_STAFF,
}

export const ACTION_MESSAGE = {
  [ACTION_TYPES.RESCHEDULE]: 'You can drag and drop this appointment',
  [ACTION_TYPES.RESIZE_SCHEDULE]: 'You can resize this appointment',
  [ACTION_TYPES.DELETE]: 'You can delete this appointment',
  [ACTION_TYPES.CANCEL]: 'You can cancel this appointment',
  [ACTION_TYPES.CHECK_OUT]: 'You can check out this patient',
  [ACTION_TYPES.EDIT_TEMPERATURE]: 'You can edit bypass temperature check for this patient',
  [ACTION_TYPES.EDIT_CONSULTATION_STAFF]: 'You can edit consultation staff for this patient',
}

export const APPOINTMENT_STATUS_ALLOWED_ACTIONS: Record<PATIENT_STATUSES, ACTION_TYPES[]> = {
  [PATIENT_STATUSES.UNCONFIRMED]: [
    ACTION_TYPES.RESCHEDULE,
    ACTION_TYPES.RESIZE_SCHEDULE,
    ACTION_TYPES.DELETE,
    ACTION_TYPES.CANCEL,
    ACTION_TYPES.EDIT_TEMPERATURE,
    ACTION_TYPES.EDIT_CONSULTATION_STAFF,
  ],
  [PATIENT_STATUSES.CONFIRMED]: [
    ACTION_TYPES.RESCHEDULE,
    ACTION_TYPES.RESIZE_SCHEDULE,
    ACTION_TYPES.DELETE,
    ACTION_TYPES.CANCEL,
    ACTION_TYPES.EDIT_TEMPERATURE,
    ACTION_TYPES.EDIT_CONSULTATION_STAFF,
  ],
  [PATIENT_STATUSES.REFUSED]: [ACTION_TYPES.DELETE, ACTION_TYPES.CANCEL],
  [PATIENT_STATUSES.CANCELED]: [ACTION_TYPES.DELETE],
  [PATIENT_STATUSES.CHECKED_IN_ON_SITE]: [
    ACTION_TYPES.CHECK_OUT,
    ACTION_TYPES.RESCHEDULE,
    ACTION_TYPES.RESIZE_SCHEDULE,
    ACTION_TYPES.EDIT_CONSULTATION_STAFF,
  ],
  [PATIENT_STATUSES.WALK_IN]: [
    ACTION_TYPES.RESCHEDULE,
    ACTION_TYPES.RESIZE_SCHEDULE,
    ACTION_TYPES.DELETE,
    ACTION_TYPES.EDIT_TEMPERATURE,
    ACTION_TYPES.EDIT_CONSULTATION_STAFF,
  ],
  [PATIENT_STATUSES.CHECKED_OUT]: [],
  [PATIENT_STATUSES.DISABLED]: [],
  [PATIENT_STATUSES.CHECK_IN_REJECTED]: [
    ACTION_TYPES.DELETE,
    ACTION_TYPES.CANCEL,
    ACTION_TYPES.EDIT_TEMPERATURE,
    ACTION_TYPES.RESCHEDULE,
    ACTION_TYPES.RESIZE_SCHEDULE,
  ],
  [PATIENT_STATUSES.NO_SHOW]: [ACTION_TYPES.DELETE, ACTION_TYPES.CANCEL],
  [PATIENT_STATUSES.DELETED]: [],
  [PATIENT_STATUSES.RESCHEDULE]: [],
}

export const COUNT_AS_OVERLAPPING = [
  PATIENT_STATUSES.CONFIRMED,
  PATIENT_STATUSES.CHECKED_IN_ON_SITE,
  PATIENT_STATUSES.WALK_IN,
  PATIENT_STATUSES.UNCONFIRMED,
]

export const ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS = [
  PATIENT_STATUSES.CONFIRMED,
  PATIENT_STATUSES.UNCONFIRMED,
  PATIENT_STATUSES.CHECKED_IN_ON_SITE,
  PATIENT_STATUSES.WALK_IN,
  PATIENT_STATUSES.NO_SHOW,
  PATIENT_STATUSES.CHECK_IN_REJECTED,
]

export enum PUSHER_EVENTS {
  PATIENT_UPDATED = 'patient_update',
  PATIENT_CREATE = 'patient_create',
  PATIENT_DELETE = 'patient_delete',
  PATIENT_LOCATION_UPDATE = 'patient_location_update',
  PATIENT_STATUS_UPDATE = 'patient_status_update',
  MASS_UPDATE = 'mass_update',
  ROOM_STATUS_UPDATE = 'room_status_update',
}

export enum TAB_KEYS {
  PATIENT_SCHEDULE = 'patient-schedule',
  PATIENT_ACTIVITY = 'patient-activity',
}

export const STATUSES_TO_BE_HIDDEN_IN_PATIENT_ACTIVITY = [
  PATIENT_STATUSES.CANCELED,
  PATIENT_STATUSES.REFUSED,
]

export enum SMS_STATUSES {
  UNCONFIRMED = 'unconfirmed',
  PENDING = 'pending',
  DELIVERED = 'delivered',
  FAILED = 'failed',
}

export const MIN_SCHEDULE_TIME_IN_MINUTES = 5
export const MIN_LONG_PRESS_TO_ADD_SCHEDULE_MS = 5
export const MIN_PRE_POPULATE_TIME = 5
