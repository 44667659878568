import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'
import type { IRequestData } from 'common/interfaces/IRequestResponse'
import type { TableParamsType } from 'common/interfaces/ITable'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'

import type { IDoctor } from 'features/Admin/interfaces/IDoctor'

const { withPaginationParams } = UtilService

export const adminApi = createApi({
  reducerPath: REDUCER_CONSTANTS.ADMIN,
  baseQuery: baseQuery(),
  tagTypes: ['Admin'],
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchDoctors: build.query({
      query: (params: TableParamsType) => ({
        url: withPaginationParams('/get-all-doctors', params),
      }),
      transformResponse: (response: IRequestData<IDoctor[]>): IRequestData<IDoctor[]> => ({
        ...response,
      }),
      providesTags: ['Admin'],
    }),

    loginAsDoctor: build.mutation({
      query: (doctorId) => ({
        url: `/log-in-as-doctor/${doctorId}`,
        method: HTTP_METHODS.POST,
      }),
      invalidatesTags: ['Admin'],
    }),
    logoutAsDoctor: build.mutation({
      query: (old_access_token) => ({
        url: `/log-out-from-another-doctor`,
        method: HTTP_METHODS.POST,
        data: { old_access_token },
      }),
      invalidatesTags: ['Admin'],
    }),
  }),
})

export const { useLazyFetchDoctorsQuery, useLoginAsDoctorMutation, useLogoutAsDoctorMutation } =
  adminApi
