export enum BUTTON_MODIFIER {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
  CALENDAR = 'calendar',
  DEFAULT = 'default',
  TERTIARY80 = 'tertiary80',
}

export enum BUTTON_PROPORTION {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum BUTTON_SEVERITY {
  DANGER = 'danger',
  SUCCESS = 'success',
  DANGER_OUTLINE = 'dangerOutline',
  SUCCESS_FILLED = 'successFilled',
}

export enum BUTTON_CONSTANTS {
  EDIT = 'Edit',
  NEXT = 'Next',
  YES = 'Yes',
  APPLY = 'Apply',
  ADD = 'Add',
  SELECT = 'Select',
  UPDATE = 'Update',
  SUBMIT = 'Submit',
  RESCHEDULE = 'Reschedule',
  REMOVE = 'Remove',
  DELETE = 'Delete',
  PREVIOUS = 'Previous',
  CLEAR_SIGNATURE = 'Clear Signature',
  CANCEL_LEASE = 'Cancel Booking',
  REQUEST_TO_COMPLETE = 'Request to Complete',
  LOGIN = 'Login',
  SIGN_UP = 'Sign Up',
  ADD_TO_CART = 'Add to cart',
  SELECT_OPTIONS = 'Select Options',
  SIGN_CONTRACT = 'Sign Contract',
  REVIEW_CONTRACT = 'Review Contract',
  COMPLETE_MAILBOX_ADDRESS = 'Complete Mailbox Address',
  CHOOSE_CONTINUE = 'Choose & Continue',
  BROWSE_SPACES_PRICES = 'Browse Spaces & Prices',
  GO_CHECKOUT = 'Go to Checkout',
  CONTINUE = 'Continue',
  SAVE = 'Save',
  CANCEL = 'Cancel',
  ADD_NEW_PLUS = '+ Add New',
  NEW_TICKET = 'New Ticket',
  UPLOAD = 'Upload',
  YES_REMOVE = 'Yes, remove',
  YES_CANCEL = 'Yes, cancel',
  NO = 'No',
  CHANGE = 'Change',
  CLOSE = 'Close',
  CLEAR = 'Clear',
  CANCEL_APPOINTMENT = 'Cancel Appointment',
  CHECK_OUT = 'Check-out',
  SEND = 'Send',
  STAY_HERE = 'Stay here',
  SKIP_FOR_NOW = 'Skip for now',
  CONFIRM_IDENTITY = 'Confirm identity',
  HOME = 'Home',
  NOTIFY_PATIENT = 'Notify patient',
  FREE = 'Free',
  ADD_STAFF = 'Add Staff',
  OK = 'Ok',
}
